// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/primitives/Headers.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/primitives/Headers.tsx");
  import.meta.hot.lastModified = "1720450759791.1765";
}
// REMIX HMR END

import { cn } from "~/utils/cn";
const headerVariants = {
  header1: {
    text: "font-sans text-2xl leading-5 md:leading-6 lg:leading-7 font-medium",
    spacing: "mb-2"
  },
  header2: {
    text: "font-sans text-base leading-6 font-medium",
    spacing: "mb-2"
  },
  header3: {
    text: "font-sans text-sm leading-5 font-medium",
    spacing: "mb-2"
  }
};
const textColorVariants = {
  bright: "text-text-bright",
  dimmed: "text-text-dimmed"
};
export function Header1({
  className,
  children,
  spacing = false,
  textColor = "bright"
}) {
  return <h1 className={cn(headerVariants.header1.text, spacing === true && headerVariants.header1.spacing, textColor === "bright" ? textColorVariants.bright : textColorVariants.dimmed, className)}>
      {children}
    </h1>;
}
_c = Header1;
export function Header2({
  className,
  children,
  spacing = false,
  textColor = "bright"
}) {
  return <h2 className={cn(headerVariants.header2.text, spacing === true && headerVariants.header2.spacing, textColor === "bright" ? textColorVariants.bright : textColorVariants.dimmed, className)}>
      {children}
    </h2>;
}
_c2 = Header2;
export function Header3({
  className,
  children,
  spacing = false,
  textColor = "bright"
}) {
  return <h3 className={cn(headerVariants.header3.text, spacing === true && headerVariants.header3.spacing, textColor === "bright" ? textColorVariants.bright : textColorVariants.dimmed, className)}>
      {children}
    </h3>;
}
_c3 = Header3;
var _c, _c2, _c3;
$RefreshReg$(_c, "Header1");
$RefreshReg$(_c2, "Header2");
$RefreshReg$(_c3, "Header3");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;